<template>
  <div class="body">
    <PreventUnload :when="hasChanges" />
    <v-navigation-drawer app class="ma-0 pa-0" v-model="mostrar">
      <opciones tipo="nuevo" />
    </v-navigation-drawer>

    <v-app-bar
      app
      class="mb-0 pb-0"
      :class="[
        {
          perdida:
            totalVenta.flete -
              totalOpcion.flete +
              totalVenta.gasto -
              totalOpcion.gasto +
              totalVenta.aduana -
              totalOpcion.aduana +
              totalVenta.almacen -
              totalOpcion.almacen <
            150,
        },
        {
          ganancia:
            totalVenta.flete -
              totalOpcion.flete +
              totalVenta.gasto -
              totalOpcion.gasto +
              totalVenta.aduana -
              totalOpcion.aduana +
              totalVenta.almacen -
              totalOpcion.almacen >
            150,
        },
      ]"
    >
      <v-app-bar-nav-icon @click="mostrar = !mostrar"></v-app-bar-nav-icon>
      <h3 v-if="!registroCompleto" color="white--text">
        REGISTRO DE COTIZACIÓN
      </h3>
      <h3 v-if="registroCompleto">COTIZACIÓN: QUOTE - {{ quote }}</h3>
      <v-flex text-right>
        GANANCIA:
        {{
          currencyFormat(
            totalVenta.flete -
              totalOpcion.flete +
              totalVenta.gasto -
              totalOpcion.gasto +
              totalVenta.aduana -
              totalOpcion.aduana +
              totalVenta.almacen -
              totalOpcion.almacen
          )
        }}
      </v-flex>
      <v-flex text-right>
        <v-btn
          v-if="!registroCompleto && botonRegistrarFlag"
          class="mx-1"
          color="teal"
          dark
          scrollable
          @click="aceptar()"
        >
          <v-icon class="mx-1">mdi-save</v-icon>Registrar
        </v-btn>
      </v-flex>
      <v-flex text-right v-if="registroCompleto">
        <v-btn
          class="mx-1"
          color="teal"
          dark
          :to="{ path: '/editar_cotizacion/' + this.id }"
        >
          <v-icon class="mx-1" dense small>mdi-pencil</v-icon>
          EDITAR
        </v-btn>
      </v-flex>
    </v-app-bar>

    <v-container elevation="10" fluid>
      <v-toolbar elevation="0" class="pa-0 ma-0">
        <v-tabs centered mobile-breakpoint show-arrows class="tabFixeado">
          <v-tab @click="obtenerIdTab(0)">Solicitar</v-tab>
          <v-tab @click="obtenerIdTab(1)" :disabled="costo">
            Costos de la Cotización</v-tab
          >
          <v-tab @click="obtenerIdTab(2)" :disabled="nota">
            Notas de Costos
          </v-tab>
          <v-tab @click="obtenerIdTab(3)" :disabled="instructivo">
            Instructivo
          </v-tab>
          <!-- <v-tab @click="obtenerIdTab(4)" :disabled="nota">Archivos</v-tab> -->
        </v-tabs>
      </v-toolbar>
      <v-tabs-items v-model="tabCotizar">
        <v-tab-item>
          <div class="row mt-3">
            <div class="col-12 col-lg-6 col-xl-6 col-md-6 pa-0">
              <datosPersonales tipo="nuevo" />
            </div>
            <div class="col-12 col-lg-6 col-xl-6 col-md-6 pa-0">
              <datosCarga v-show="carga" id="carga" tipo="nuevo" />
            </div>
            <div class="col-12">
              <datosServicios v-show="servicio" id="servicio" tipo="nuevo" />
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <cardCostos tipo="nuevo" />
        </v-tab-item>
        <v-tab-item>
          <cardNotas tipo="nuevo" />
        </v-tab-item>
        <v-tab-item>
          <instructivo tipo="nuevo" />
        </v-tab-item>
        <v-tab-item>
          <div class="row">
            <div class="col-12 col-lg-6 col-xl-6 col-md-6 pa-5">
              <h3><p>LISTADO DE ARCHIVOS</p></h3>
              <listaArchivos tipo="nuevo" />
            </div>
            <div class="col-12 col-lg-6 col-xl-6 col-md-6 pa-5">
              <h3><p>CARGA DE ARCHIVOS</p></h3>
              <cargarAchivos />
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>

      <v-dialog v-model="dialogIntructivo" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            <p>GENERANDO INSTRUCTIVO</p>
            <p>Un momento por favor...</p>
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
    <v-dialog
      v-model="modalCargando"
      scrollable
      persistent
      :overlay="false"
      max-width="600px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title> Obteniendo datos ... </v-card-title>

        <v-card-text>
          <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-footer app> </v-footer>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";
import funcion from "@/mixins/funciones";
import PreventUnload from "vue-prevent-unload";
import Swal from "sweetalert2";

export default {
  mixins: [funcion],
  name: "registrarCotizacionComponet",
  components: {
    PreventUnload,
    opciones: () => import("@/components/comun/opcionesRegistro.vue"),
    datosPersonales: () =>
      import("@/components/Cotizacion/cardDatosPersonalesComponent.vue"),
    datosCarga: () =>
      import("@/components/Cotizacion/cardDatosCargaComponent.vue"),
    datosServicios: () =>
      import("@/components/Cotizacion/cardServicioComponent.vue"),
    cardCostos: () => import("@/components/Cotizacion/cardCostosComponent.vue"),
    cardNotas: () => import("@/components/Cotizacion/cardNotasComponent.vue"),
    instructivo: () =>
      import("@/components/Cotizacion/cardInstructivoComponet.vue"),
    listaArchivos: () =>
      import("@/components/comun/listaArchivosComponent.vue"),
    cargarAchivos: () =>
      import("@/components/comun/cargarArchivosComponet.vue"),
  },
  data() {
    return {
      hasChanges: true,
      modalCargando: false,
      quote: "",
      dialogIntructivo: false,
      mostrar: false,
      tabCotizar: 0,
      carga: false,
      servicio: false,
      costo: true,
      nota: true,
      instructivo: true,
      id: 0,
    };
  },
  methods: {
    ...mapActions([
      "getQuoteStatus",
      "getEntities",
      "getModality",
      "getShipment",
      "getIncoterms",
      "getPortBegin",
      "getPortEnd",
      "getContainersList",
      "getBegEndList",
      "getCityPricing",
      "getModuleRole",
      "getInstructivoId",
      "getFilesQuote",
      "getMultiplicador",
      "getMarketingList",
      "getQuoteSales",
    ]),
    ...mapMutations([
      "setProvincias",
      "setDistritos",
      "setServices",
      "setRole",
      "setMultiplicador",
      "setContainersList",
      "setDatosClientes",
      "opcioneMenuLista",
      "setregistroCompleto",
      "setDialog",
      "setCostos",
      "setearDatos",
      "setInstructivo",
      "setPrecotizarFlag",
      "setBotonRegistrarFlag",
      "setArchivosFlag",
      "setListFiile",
    ]),

    obtenerIdTab(id) {
      let opciones = [
        {
          id: "0",
          nombre: "Datos Principales",
          class: id == 0 ? "active" : "inactive",
        },
        {
          id: "1",
          nombre: "Costos Cotización",
          class: id == 1 ? "active" : "inactive",
        },
        {
          id: "2",
          nombre: "Notas de Costos",
          class: id == 2 ? "active" : "inactive",
        },
        {
          id: "3",
          nombre: "Instructivo",
          class: id == 3 ? "active" : "inactive",
        },
      ];
      this.opcioneMenuLista(opciones);
      this.tabCotizar = id;
    },
    aceptar(gen_intru = true) {
      if (
        !this.datosPrincipales.fecha_fin &&
        !this.datosPrincipales.tiempo_transito &&
        gen_intru == true
      ) {
        if (!this.datosPrincipales.fecha_fin) {
          Swal.fire({
            icon: "warning",
            title: "ADVERTENCIA",
            text: "La fecha de validación es requierida",
          });
        } else if (!this.datosPrincipales.tiempo_transito) {
          Swal.fire({
            icon: "warning",
            title: "ADVERTENCIA",
            text: "El tiempo de transito es requerido",
          });
        }
      } else {
        let serv = [];
        this.services
          .filter((v) => v.esventaflag == 0)
          .forEach((element) => {
            serv.push({
              idBegEnd: element.id_begend,
              nameservice: element.namegroupservice,
              statusService: element.status,
              id_groupservices: element.id_groupservices
                ? element.id_groupservices
                : "",
              codegroupservices: element.codegroupservices,
              codeItemservices: element.codeItemservices,
              id_begend: element.id_begend,
            });
          });
        /* GUARDAR COSTOS */
        let cost = [];

        this.costos
          .filter((v) => v.statedelete == 0)
          .forEach((element) => {
            let codemultiplicador = element.id_multiplicador
              ? this.multiplicador.filter(
                  (v) => v.id == element.id_multiplicador
                  )[0] ? this.multiplicador.filter(
                  (v) => v.id == element.id_multiplicador
                  ) : []
              : [];
              let subTotal = 0;
             
            if (codemultiplicador.length>0) {
              if (
                codemultiplicador.code != 5 &&
                codemultiplicador.code != 13 &&
                codemultiplicador.code != 14
              ) {
                subTotal =
                  codemultiplicador.valor *
                  element.costounitario *
                  this.calcularFac(
                    codemultiplicador.code,
                    this.datosPrincipales.metroscc,
                    this.datosPrincipales.kg,
                    this.datosPrincipales.containers,
                    this.datosPrincipales.amount
                  );
              } else if (
                codemultiplicador.code == 5 ||
                codemultiplicador.code == 13 ||
                codemultiplicador.code == 14
              ) {
                this.calcularValor(
                  this.datosPrincipales.amount,
                  this.totalDeFlete,
                  codemultiplicador.code,
                  codemultiplicador.code == 14 ? element.cif : element.seguro
                );
              }
            } else {
              subTotal = 0;
            }
            if (
              !(
                codemultiplicador.code == 5 &&
                codemultiplicador.code == 13 &&
                codemultiplicador.code == 14 &&
                element.esorigenflag
              )
            ) {
              cost.push({
                id_proveedor: element.id_proveedor,
                id_multiplicador: element.id_multiplicador,
                concepto: element.nameservice,
                costounitario: element.costounitario,
                minimo: element.minimo ? element.minimo : 0,
                esorigenflag: element.esorigenflag,
                eslocalflag: element.eslocalflag,
                esaduanaflag: element.esaduanaflag,
                esalmacenflag: element.esalmacenflag,
                esopcionflag: element.esventaflag == 1 ? 0 : 0,
                status: element.status,
                esventaflag: element.esventaflag,
                cif: element.cif,
                seguro: element.seguro,
                subtotal: subTotal,
              });
            }
          });
        /* GUARDAR NOTAS */
        let notas = [];

        this.datosPrincipales.notesFixed
          .filter((v) => v.state == 1)
          .forEach((element) => {
            notas.push({
              descripcion: element.description,
              esprincipalflag: 1,
              esincluyeflag: 0,
              esnoincluyeflag: 0,
              status: element.state ? element.state : 1,
            });
          });
        /**GUARDAR GANANCIA PARA CASILLERO */
        let ganancia = 0;
        this.quoteSales.forEach((element) => {
          ganancia += parseFloat(element.monto);
        });
        /**GUARDAR GANANCIA PARA CASILLERO */
        let ventascasillero = [];
        this.quoteSales.forEach((element) => {
          ventascasillero.push({
            id_quoteSales: element.id,
            description: element.description,
            monto: element.monto ? element.monto : 0,
          });
        });
        /* GUARDAR CONTENEDORES */
        let cont = [];
        if (this.datosPrincipales.idshipment.value == 2) {
          this.datosPrincipales.containers.forEach((element) => {
          
            cont.push({
              id_contenedor: element.id,
              cantidad: element.cantidad,
              status: true,
            });
          });
        } else {
          cont = [];
        }
      
        let data = {
          fullflag: true,
          id_entitie: this.datosPrincipales.idCliente,
          idVendedor: this.datosPrincipales.identities,
          idPricing: this.datosPrincipales.idPricing,
          idsentido: this.datosPrincipales.idmodality,
          idtipocarga: this.datosPrincipales.idshipment.value,
          idincoterms: this.datosPrincipales.idincoterm,
          idorigen: this.datosPrincipales.idPortBegin,
          iddestino: this.datosPrincipales.idPortEnd,
          numerobultos: this.datosPrincipales.bultos,
          peso: this.datosPrincipales.kg,
          volumen: this.datosPrincipales.metroscc,
          statusquote: this.datosPrincipales.idstatus,
          monto: this.datosPrincipales.amount,
          serviciocotizacion: serv,
          costocotizacion: cost,
          notacosto: notas,
          contenedores: cont,
          proveedor: this.datosPrincipales.proveedor,
          telefonoproveedor: this.datosPrincipales.telefonoproveedor,
          direccionproveedor: this.datosPrincipales.direccionproveedor,
          iddistrito: this.datosPrincipales.iddistrito,
          idprovincia: this.datosPrincipales.idprovincia,
          impuestos: this.datosPrincipales.impuestos,
          id_marketing: this.datosPrincipales.idmarketing,
          descripcionMercancia: this.datosPrincipales.descripcioncarga
            ? this.datosPrincipales.descripcioncarga
            : "",
          fecha_fin: this.datosPrincipales.fecha_fin,
          tiempo_transito: this.datosPrincipales.tiempo_transito,
          ventascasillerodetalles: ventascasillero,
          ganancia: ganancia,
          id_branch: JSON.parse(localStorage.getItem("branch")),
        };
        let token = JSON.parse(localStorage.getItem("token"));
        let config = {
          method: "post",
          url: process.env.VUE_APP_URL_MAIN + "setQuote",
          headers: {
            "auth-token": token,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config).then((response) => {
          let data = response.data;
          this.id = data.insertId;
          this.datosPrincipales.id = data.insertId;
          this.quote = data.nro_quote;
          this.datosPrincipales.quote = data.nro_quote;
          this.subirArchivos(data.insertId, this.archivos, 0);
          Swal.fire("CORRECTO", response.data.msg, "success").then((result) => {
            if (result.isConfirmed) {
              if (gen_intru == true) {
                this.obtenerInstructivo(this.id);
              } else {
                this.modalCargando = true;

                setTimeout(() => {
                  this.modalCargando = false;

                  this.$router.push("listado");
                }, 500);
              }
            }
          });
        });

        this.costo = false;
        this.nota = false;
        this.instructivo = false;

        // this.obtenerIdTab(3);
        // this.setregistroCompleto(true);
      }
    },

    async subirArchivos(id, datos, index) {
      let i = index;
      if (datos.length > i) {
        let formData = new FormData();
        // var valores = null;
        formData.append("name", datos[i].name);
        formData.append("file", datos[i]);
        formData.append("id_quote", id);
        axios
          .post(process.env.VUE_APP_URL_MAIN + "uploadFilesSingle", formData, {
            "Content-Type": "multipart/form-data",
            "auth-token": JSON.parse(localStorage.getItem("token")),
          })
          .then((response) => {
            i++;
            if (response.data.statusBol == true) {
              this.subirArchivos(id, datos, i);
            }
          });
      } else {
        this.getFilesQuote(id);
        this.setArchivosFlag();
        setTimeout(() => {
          this.modalCargando = false;
        }, 3000);
      }
    },

    async getItemsServices(idmodality, idshipment, idincoterm) {
      let data = {
        id_modality: idmodality,
        id_shipment: idshipment,
        id_incoterms: idincoterm,
        id_branch: JSON.parse(localStorage.getItem("branch")),
      };
      let valores = await this.obtenerDataApi(
        "post",
        process.env.VUE_APP_URL_MAIN + "getItemsServices",
        data
      );
      let servicios = [];
      for (let i = 0; i < valores.length; i++) {
        valores[i].groupservice.forEach((element) => {
          if (
            servicios.filter(
              (v) => v.namegroupservice == element.namegroupservice
            ).length == 0
          ) {
            servicios.push({
              id_groupservices: element.id_groupservices,
              id_incoterms: element.id_incoterms,
              id_modality: element.id_modality,
              id_multiplicador: element.id_multiplicador
                ? element.id_multiplicador
                : "N",
              id_services: element.id_services,
              id_shipment: element.id_shipment,
              namebegend: element.namebegend,
              namegroupservice: element.namegroupservice,
              namemultiplicador: element.namemultiplicador,
              namerole: element.namerole,
              price: element.price,
              id: element.id,
              id_begend: element.id_begend,
              nameservice: element.nameservice,
              status: element.status,
              id_role: element.id_role,
              esventaflag: 0,
              statedelete: 0,
              servicioFlag: 1,
              costosflag: 0,
              codegroupservices: element.codegroupservices,
              codeItemservices: element.codeItemservices,
            });
          }
        });
      }
      this.guardarServicios(servicios.filter((v) => v.esventaflag == 0));
    },

    guardarServicios(servicios) {
      this.setServices(servicios);
    },

    async getItemsServicesDetails(idmodality, idshipment, idincoterm) {
      let data = {
        id_modality: idmodality,
        id_shipment: idshipment,
        id_incoterms: idincoterm,
        id_branch: JSON.parse(localStorage.getItem("branch")),
      };
      let valores = await this.obtenerDataApi(
        "post",
        process.env.VUE_APP_URL_MAIN + "getItemsServicesDetails", //9800
        data
      );

      let servicios = [];
      for (let i = 0; i < valores.length; i++) {
        valores[i].groupservice.forEach((element) => {
          if (
            servicios.filter(
              (v) =>
                v.id_proveedor == element.id_proveedor &&
                v.nameservice == element.nameservice &&
                v.id_multiplicador == element.id_multiplicador &&
                v.costounitario == element.price
            ).length == 0
          ) {
            servicios.push({
              id_groupservices: element.id_groupservices,
              id_incoterms: element.id_incoterms,
              id_modality: element.id_modality,
              id_multiplicador: element.id_multiplicador
                ? element.id_multiplicador
                : "N",
              id_services: element.id_services,
              id_shipment: element.id_shipment,
              namebegend: element.namebegend,
              namegroupservice: element.namegroupservice,
              namemultiplicador: element.namemultiplicador,
              namerole: element.namerole,
              costounitario: element.price ? element.price : 0,
              id: element.id,
              id_begend: element.id_begend,
              nameservice: element.nameservice,
              status: element.status,
              id_role: element.id_role,
              statedelete: 0,
              servicioFlag: 1,
              costosflag: 0,
              cif: parseFloat(0.35),
              seguro: parseFloat(0.45),
              id_proveedor: element.id_proveedor ? element.id_proveedor : 0,
              esopcionflag: 1,
              esventaflag: 0,
              //
              esorigenflag:
                element.id_modality == 1
                  ? element.id_begend == 1 ||
                    element.id_begend == 3 ||
                    element.id_begend == 6 ||
                    element.id_begend == 8
                    ? 1
                    : 0
                  : //  element.id_begend == 1 ||
                  element.id_begend == 6 /*||
                  element.id_begend == 6 ||
                  element.id_begend == 8*/
                  ? 1
                  : 0,
              eslocalflag:
                element.id_modality == 1
                  ? (element.id_begend == 2 || element.id_begend == 7) &&
                    element.id_role != 4 &&
                    element.id_role != 3 &&
                    element.id_role != 7
                    ? 1
                    : 0
                  : // element.id_begend == 1 ||
                  element.id_begend == 3 &&
                    element.id_role != 4 &&
                    element.id_role != 3 &&
                    element.id_role != 7
                  ? 1
                  : 0,

              esaduanaflag:
                element.id_modality == 1
                  ? (element.id_begend == 2 || element.id_begend == 7) &&
                    (element.id_role == 4 || element.id_role == 3)
                    ? 1
                    : 0
                  : element.id_begend == 1 &&
                    (element.id_role == 4 || element.id_role == 3)
                  ? 1
                  : 0,
              esalmacenflag:
                element.id_modality == 1
                  ? (element.id_begend == 2 || element.id_begend == 7) &&
                    element.id_role == 7
                    ? 1
                    : 0
                  : element.id_begend == 7 && element.id_role == 7
                  ? 1
                  : 0,
              //
            });
            servicios.push({
              id_groupservices: element.id_groupservices,
              id_incoterms: element.id_incoterms,
              id_modality: element.id_modality,
              id_multiplicador: element.id_multiplicador
                ? element.id_multiplicador
                : "N",
              id_services: element.id_services,
              id_shipment: element.id_shipment,
              namebegend: element.namebegend,
              namegroupservice: element.namegroupservice,
              namemultiplicador: element.namemultiplicador,
              namerole: element.namerole,
              costounitario: 0,
              id: element.id,
              id_begend: element.id_begend,
              nameservice: element.nameservice,
              status: element.status,
              id_role: element.id_role,
              cif: 0,
              seguro: 0,
              statedelete: 0,
              costosflag: 0,
              id_proveedor: element.id_proveedor,
              esventaflag: 1,
              esorigenflag:
                element.id_modality == 1
                  ? element.id_begend == 1 ||
                    element.id_begend == 3 ||
                    element.id_begend == 6 ||
                    element.id_begend == 8
                    ? 1
                    : 0
                  : //  element.id_begend == 1 ||
                  element.id_begend == 6 /*||
                  element.id_begend == 6 ||
                  element.id_begend == 8*/
                  ? 1
                  : 0,
              eslocalflag:
                element.id_modality == 1
                  ? (element.id_begend == 2 || element.id_begend == 7) &&
                    element.id_role != 4 &&
                    element.id_role != 3 &&
                    element.id_role != 7
                    ? 1
                    : 0
                  : // element.id_begend == 1 ||
                  element.id_begend == 3 &&
                    element.id_role != 4 &&
                    element.id_role != 3 &&
                    element.id_role != 7
                  ? 1
                  : 0,

              esaduanaflag:
                element.id_modality == 1
                  ? (element.id_begend == 2 || element.id_begend == 7) &&
                    (element.id_role == 4 || element.id_role == 3)
                    ? 1
                    : 0
                  : element.id_begend == 1 &&
                    (element.id_role == 4 || element.id_role == 3)
                  ? 1
                  : 0,
              esalmacenflag:
                element.id_modality == 1
                  ? (element.id_begend == 2 || element.id_begend == 7) &&
                    element.id_role == 7
                    ? 1
                    : 0
                  : element.id_begend == 7 && element.id_role == 7
                  ? 1
                  : 0,
              //
            });
          }
        });
      }

      this.guardaCostos(servicios);
    },

    guardaCostos(servicios) {
      this.setCostos(servicios);
    },

    async obtenerTipoEmbarque() {
      let valores = await this.obtenerDataApi(
        "post",
        process.env.VUE_APP_URL_MAIN + "getContainersList"
      );
      let container = [];
      valores.forEach((element) => {
        container.push({ value: element.id, text: element.name });
      });
      this.setContainersList(container);
    },

    async obtenerDatosCliente() {
      let valores = await this.obtenerDataApi(
        "post",
        process.env.VUE_APP_URL_MAIN + "getEntitiesList",
        {
          id_role: 18,
          id_branch: JSON.parse(localStorage.getItem("branch")),
        }
      );
      let clientes = [];
      valores.forEach((element) => {
        clientes.push({
          id: element.id,
          namelong: element.namelong,
          documentlong: element.documentlong,
          phone: element.phone,
        });
      });
      this.setDatosClientes(clientes);
    },

    async obtenerInstructivo(id) {
      this.dialogIntructivo = true;
      this.getInstructivoId(id);

      setTimeout(this.cambiarStep, 2000);
    },

    cambiarStep() {
      this.setregistroCompleto(true);
      this.obtenerIdTab(3);
      this.dialogIntructivo = false;
      this.instructivo = false;
    },
  },
  computed: {
    ...mapState([
      "quoteSales",
      "registroCompleto",
      "datosPrincipales",
      "stepCarga",
      "stepServicio",
      "stepCosto",
      "stepNota",
      "stepInstructivo",
      "datosServiciosFlag",
      "nuevoUsuarioFlag",
      "cudFlag",
      "services",
      "costos",
      "dialog",
      "totalVenta",
      "totalOpcion",
      "servicios",
      "botonRegistrarFlag",
      "archivos",
      "registrarFlag",
      "multiplicador",
      "totalDeFlete",
    ]),
  },
  created() {},
  beforeMount() {
    this.setearDatos();
    let opciones = [
      {
        id: 1,
        nombre: "Datos Principales",
        class: "active",
      },
      {
        id: 4,
        nombre: "Costos Cotización",
      },
      {
        id: 5,
        nombre: "Notas de Costos",
      },
      {
        id: 6,
        nombre: "Instructivo",
      },
    ];
    this.opcioneMenuLista(opciones);
    this.getQuoteStatus();
    this.getEntities();
    this.getModality();
    this.getShipment();
    this.getIncoterms();
    this.getModuleRole();
    this.getContainersList();
    this.obtenerDatosCliente();
    this.setregistroCompleto(false);
    this.getBegEndList();
    this.getCityPricing();
    this.getMarketingList();
  },
  mounted() {
    this.setPrecotizarFlag(false);
    this.setBotonRegistrarFlag(false);
  },
  watch: {
    stepCarga() {
      this.getPortBegin(this.datosPrincipales.idshipment.idTransporte);
      this.getPortEnd(this.datosPrincipales.idshipment.idTransporte);
      this.getItemsServices(
        this.datosPrincipales.idmodality,
        this.datosPrincipales.idshipment.value,
        this.datosPrincipales.idincoterm
      );
      this.carga = true;
      this.$router
        .push({
          name: "datoscarga",
          hash: "#carga",
        })
        .catch(() => {});
    },
    stepServicio() {
      this.getItemsServicesDetails(
        this.datosPrincipales.idmodality,
        this.datosPrincipales.idshipment.value,
        this.datosPrincipales.idincoterm
      );

      this.getMultiplicador({ id: this.datosPrincipales.idshipment.value });
      this.getQuoteSales();
      this.servicio = true;
    },
    stepCosto() {
      // this.tabCotizar = 1;
      this.datosPrincipales.min = parseFloat(this.datosPrincipales.amount);

      this.obtenerIdTab(1);
      this.costo = false;
    },
    stepNota() {
      // this.tabCotizar = 2;
      this.obtenerIdTab(2);
      this.nota = false;
    },
    nuevoUsuarioFlag() {
      this.obtenerDatosCliente();
    },

    cudFlag() {
      this.aceptar(this.cudFlag);
    },
    registroCompleto() {
      this.instructivo = false;
    },
    registrarFlag() {
      this.aceptar(true);
    },
  },
};
</script>

<style>
.tabFixeado {
  position: absolute !important; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
}
.perdida {
  background: red !important;
  color: white !important;
  font-weight: bold;
  font-size: large;
}
.ganancia {
  background: rgb(32, 110, 32) !important;
  color: white !important;

  font-weight: bold;
  font-size: large;
}
</style>
